<template>
  <div>

  <!--Footer -->
  <footer>
    <!-- <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-sm-6">
            <h6>Top Categores</h6>
            <ul>
              <li><a href="#">Car Brand Name</a></li>
              <li><a href="#">Car Brand Name 2</a></li>
              <li><a href="#">Car Brand Name 3</a></li>
              <li><a href="#">Car Brand Name 4</a></li>
              <li><a href="#">Car Brand Name 5</a></li>
            </ul>
          </div>
          <div class="col-md-3 col-sm-6">
            <h6>About Us</h6>
            <ul>
              <li><a href="#">Privacy</a></li>
              <li><a href="#">Hybrid Cars</a></li>
              <li><a href="#">Cookies</a></li>
              <li><a href="#">Trademarks</a></li>
              <li><a href="#">Terms of use</a></li>
            </ul>
          </div>
          <div class="col-md-3 col-sm-6">
            <h6>Useful Links</h6>
            <ul>
              <li><a href="#">Our Partners</a></li>
              <li><a href="#">Careers</a></li>
              <li><a href="#">Sitemap</a></li>
              <li><a href="#">Investors</a></li>
              <li><a href="#">Request a Quote</a></li>
            </ul>
          </div>
          <div class="col-md-3 col-sm-6">
            <h6>Subscribe Newsletter</h6>
            <div class="newsletter-form">
              <form action="#">
                <div class="form-group">
                  <input type="email" class="form-control newsletter-input" required placeholder="Enter Email Address" />
                </div>
                <button type="submit" class="btn btn-block">Subscribe <span class="angle_arrow"><i class="fa fa-angle-right" aria-hidden="true"></i></span></button>
              </form>
              <p class="subscribed-text">*We send great deals and latest auto news to our subscribed users every week.</p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="footer-bottom">
      <div class="container">
        <div class="row">
          
          <div class="col-md-6 ">
            <p class="copy-right">Copyright &copy; {{ currentYear }} Midland Auto Sales. All Rights Reserved</p>
          </div>
      <div class="col-md-6 text-right">
            <!-- <div class="footer_widget">
              <p>Download Our APP:</p>
              <ul>
                <li><a href="#"><i class="fa fa-android" aria-hidden="true"></i></a></li>
                <li><a href="#"><i class="fa fa-apple" aria-hidden="true"></i></a></li>
              </ul>
            </div> -->
            <!-- <div class="footer_widget">
              <p>Connect with Us:</p>
              <ul>
                <li><a href="#"><i class="fa fa-facebook-square" aria-hidden="true"></i></a></li>
                <li><a href="#"><i class="fa fa-twitter-square" aria-hidden="true"></i></a></li>
                <li><a href="#"><i class="fa fa-linkedin-square" aria-hidden="true"></i></a></li>
                <li><a href="#"><i class="fa fa-google-plus-square" aria-hidden="true"></i></a></li>
                <li><a href="#"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
              </ul>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- /Footer--> 
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Footer',
  computed: {
    currentYear() {
      return moment().format('yyyy')
    }
  }
}
</script>
